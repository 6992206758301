<template>
    <div class="index">
        <div class="index-top">
            <div class="logo" @click="goto('/index/')">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/logo.png)"></div>
                </div>
            </div>
            <!-- <a class="login" :href="base.adminLink">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/manager.png)"></div>
                </div>
                管理员登录
            </a> -->
        </div>
        <div class="index-center">
            <div class="itemlist">
                <div class="item" v-for="(item, index) in menuList" :key="index"
                    @click="show(index, item.url)"
                    :style="{ backgroundColor: item.color }">
                    <div class="txt">
                        <div class="t1">{{ item.title }}</div>
                        <div class="t2" v-if="item.info" v-html="item.info"></div>
                    </div>
                    <div class="icon">
                        <div class="picbox">
                            <div class="pic" :style="'background-image: url(/img/index_icon' + (index + 1) + '.png)'"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup" :class="{ active: telShow }">
            <div class="shadow" @click="telCancel(1)"></div>
            <div class="tel-popup">
                <div class="img">
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/popup_tel.png)"></div>
                    </div>
                </div>
                <div class="txt">
                    <div class="t1">您好，请问是否确认拨打962525上海市心理热线?</div>
                </div>
                <div class="btns">
                    <button class="btn" @click="telCancel(1)">取消拨号</button>
                    <button class="btn" @click="ringUp">确认拨号</button>
                </div>
            </div>
        </div>
        <div class="popup" :class="{ active: telConnected }">
            <div class="shadow" @click="telCancel(2)"></div>
            <div class="tel-popup">
                <div class="img">
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/popup_tel.png)"></div>
                    </div>
                </div>
                <div class="txt">
                    <div class="t1">该服务点暂未开通此服务</div>
                </div>
                <div class="btns">
                    <button class="btn" @click="telCancel(2)">取消</button>
                </div>
            </div>
        </div>
        <div class="popup" :class="{ active: telRinging }">
            <div class="shadow" @click="telCancel(3)"></div>
            <div class="tel-popup">
                <div class="img">
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/popup_tel.png)"></div>
                    </div>
                </div>
                <div class="txt">
                    <div class="t1">拨号中。。。</div>
                </div>
                <div class="btns">
                    <button class="btn" @click="telPopup()">挂断</button>
                </div>
            </div>
        </div>
        <div class="popup" :class="{ active: telActive }">
            <div class="shadow" @click="telCancel(4)"></div>
            <div class="tel-keyboard">
                <div class="img">
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/popup_tel.png)"></div>
                    </div>
                </div>
                <div class="txt">
                    <div class="t1">
                        {{ keyboardTxt }}
                    </div>
                </div>
                <div class="btns">
                    <button class="keyboard" @click="keyboardDown('1')">1</button>
                    <button class="keyboard" @click="keyboardDown('2')">2</button>
                    <button class="keyboard" @click="keyboardDown('3')">3</button>
                </div>
                <div class="btns">
                    <button class="keyboard" @click="keyboardDown('4')">4</button>
                    <button class="keyboard" @click="keyboardDown('5')">5</button>
                    <button class="keyboard" @click="keyboardDown('6')">6</button>
                </div>
                <div class="btns">
                    <button class="keyboard" @click="keyboardDown('7')">7</button>
                    <button class="keyboard" @click="keyboardDown('8')">8</button>
                    <button class="keyboard" @click="keyboardDown('9')">9</button>
                </div>
                <div class="btns">
                    <button class="keyboard" @click="keyboardDown('*')">*</button>
                    <button class="keyboard" @click="keyboardDown('0')">0</button>
                    <button class="keyboard" @click="keyboardDown('#')">#</button>
                </div>
                <div class="btns">
                    <button class="btn" @click="telPopup()">挂断</button>
                </div>
            </div>
        </div>
        <div class="popup" :class="{ active: telHangup }">
            <div class="shadow" @click="telCancel(5)"></div>
            <div class="tel-popup">
                <div class="img">
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/popup_tel.png)"></div>
                    </div>
                </div>
                <div class="txt">
                    <div class="t1">通话已结束</div>
                </div>
                <div class="btns">
                    <button class="btn" @click="telCancel(5)">退出</button>
                </div>
            </div>
        </div>
        <div class="popup" :class="{ active: testShow }">
            <div class="shadow" @click="testClosePopup"></div>
            <div class="test-popup">
                <div class="tishi">
                    <div class="txt">
                        <div class="t1">请选择您要测评的类型</div>
                        <div class="t2">请从以下测评的分类中，选择一个您适用的量表，然后点击“下一步”填写您的基本信息，进行测评</div>
                    </div>
                    <button class="btn" @click="createTestLink()">下一步</button>
                </div>
                <div class="cardlist">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="(item, index) in popupCard" :key="index">
                                <div class="card" :style="{ background: item.bgColor }">
                                    <div class="card-main">
                                        <div class="titlebox">
                                            <div class="img">
                                                <div class="picbox">
                                                    <div class="pic" :style="{ backgroundImage: 'url(' + item.icon + ')' }">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="title">
                                                <div class="atitle">{{ item.atitle }}</div>
                                                <div class="btitle">{{ item.btitle }}</div>
                                            </div>
                                        </div>
                                        <div class="input">
                                            <div class="radio" v-for="(val, k) in item.radios" :key="k">
                                                <input :id="'radio' + index + k" type="radio" :value="val.value"
                                                    v-model="value" />
                                                <label :for="'radio' + index + k">{{ val.key }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bg">
                                        <div class="picbox">
                                            <div class="pic" style="background-image: url(/img/popup_cardbg.png)"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="popup msgbox" :class="{ active: msgShow }">
            <div class="shadow" @click="msgClosePopup()"></div>
            <div class="tel-popup">
                <div class="txt">
                    <div class="t1">此功能尚未开放，敬请期待！</div>
                </div>
                <div class="btns">
                    <button class="btn" @click="msgClosePopup()">知道了</button>
                </div>
            </div>
        </div>

        <div class="popup" :class="{ active: chooseYykpShow }">
            <div class="shadow" @click="chooseYykeClosePopup()"></div>
            <div class="fwzx-popup">
                <div class="title">
                    <div class="picbox">
                        <div class="pic">影音科普</div>
                    </div>
                </div>
                <div class="txt">
                    <div class="btns">
                        <button class="btn" @click="chooseYykeItem(1)">精神康复</button>
                        <button class="btn" @click="chooseYykeItem(2)">心理健康</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup" :class="{ active: chooseFwzxShow }">
            <div class="shadow" @click="chooseFwzxClosePopup()"></div>
            <div class="fwzx-popup">
                <div class="title">
                    <div class="picbox">
                        <div class="pic">服务资讯</div>
                    </div>
                </div>
                <div class="txt">
                    <div class="btns">
                        <button class="btn" @click="chooseFwzxItem(1)">活动预告</button>
                        <button class="btn" @click="chooseFwzxItem(2)">活动资讯</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup" :class="{ active: chooseFwsqShow }">
            <div class="shadow" @click="chooseFwsqClosePopup()"></div>
            <div class="fwsq-popup">
                <div class="title">
                    <div class="picbox">
                        <div class="pic">服务申请</div>
                    </div>
                </div>
                <div class="txt">
                    <div class="btns">
                        <button class="btn" @click="chooseFwsqItem(1)">课程菜单</button>
                        <button class="btn" @click="chooseFwsqItem(2)">课程预约</button>
                        <button class="btn" @click="chooseFwsqItem(3)">个体心理咨询预约</button>
                    </div>
                </div>
            </div>
        </div>

        <video width=800 id="webcam" autoplay="autoplay" hidden="true"></video>
    </div>
</template>

<script>
import { getBase, getUserInfo, getWebrtcParams, getInfo, getBook, getXinlijiankang, getJinshenkangfu, getZixun, getZixunNotice, getCourse, getCourseOrder, getComNetList, getHuiminPolicy } from "@/js/request";
import { getMusicList } from "@/js/request";
import { ElMessage } from 'element-plus'
import { loginVerto, doCall, decline, logout, keyboardClick } from '../js/verto.js'
import Swiper from 'swiper'

// @ is an alias to /src
export default {
    name: 'Index',
    components: {
    },
    data() {
        return {
            base: {},
            menuList: [
                {
                    title: '心理测评',
                    info: '',
                    url: '',
                    color: '#8B9CD2'
                },
                {
                    title: '图文科普',
                    info: '',
                    url: '/mentality/tushu',
                    color: '#E99B7D'
                },
                {
                    title: '影音科普',
                    info: '',
                    url: '',
                    color: '#75ABCA'
                },
                {
                    title: '放松训练',
                    info: '',
                    url: '/musicplayer',
                    color: '#DE8EA2'
                },
                {
                    title: '服务资讯',
                    info: '',
                    url: '',
                    color: '#B8D47A'
                },
                {
                    title: '服务申请',
                    info: '',
                    url: '',
                    color: '#FEB951'
                },
                {
                    title: '惠民政策',
                    info: '',
                    url: '/mentality/huiminPolicy',
                    color: '#6DC2CD'
                },
                {
                    title: '资源分布',
                    info: '',
                    url: '/contact',
                    color: '#7CC2A6'
                },
                {
                    title: '心理热线',
                    info: '上海市心理热线：962525<br/>嘉定区心理热线：39590800',
                    url: '',
                    color: '#7C86BB'
                },
                // {
                //     title: '活动招募',
                //     info: '',
                //     url: '/event',
                //     color: '#DE8EA2'
                // },
                // {
                //     title: '咨询服务',
                //     info: '',
                //     url: '/mentality/zixun',
                //     color: '#7C86BB'
                // }
            ],
            musics: [],
            msgShow: false,
            chooseYykpShow: false,
            chooseFwzxShow: false,
            chooseFwsqShow: false,
            telShow: false,
            telConnected: false,
            telRinging: false,
            telActive: false,
            telHangup: false,
            testShow: false,
            popupCard: [
                {
                    bgColor: 'linear-gradient(0deg, #FF743E 0%, #FF8E62 100%)', icon: '/img/popup_icon1.png', atitle: '儿童及青少年', btitle: '心理测评', value: '',
                    radios: [{ key: '儿童社交焦虑量表', value: 'YXT_SASC' }, { key: '艾森克人格问卷（少年版）', value: 'YXT_EPQC' }, { key: '考试焦虑量表', value: 'YXT_TAS' }, { key: '儿童青少年压力量表', value: 'YXT_CASS' }]
                },
                {
                    bgColor: 'linear-gradient(0deg, #3F65E0 0%, #6081EA 100%)', icon: '/img/popup_icon2.png', atitle: '成年人', btitle: '心理测评', value: '',
                    radios: [{ key: '宗(Zung)氏抑郁自评量表', value: 'YXT_SDS' }, { key: '宗(Zung)氏焦虑自评量表', value: 'YXT_SAS' }, { key: '蒙哥马利抑郁评定量表', value: 'YXT_MADRS' }, { key: '患者健康问卷焦虑分量表', value: 'YXT_GAD-7' }, { key: '患者健康问卷抑郁筛查', value: 'YXT_PHQ-9' }, { key: 'Locke-Wallace婚姻调适评定', value: 'YXT_LWM' }, { key: '压力知觉量表', value: 'YXT_CPSS' }, { key: '大五人格问卷简版', value: 'YXT_NEO' }, { key: '艾森克人格问卷简式量表', value: 'YXT_EPQ-RSC' }]
                },
                {
                    bgColor: 'linear-gradient(0deg, #43CF8D 0%, #5DD99E 100%)', icon: '/img/popup_icon3.png', atitle: '老年人', btitle: '心理测评', value: '',
                    radios: [{ key: '老年抑郁量表', value: 'YXT_GDS' }, { key: 'UCLA孤独量表', value: 'YXT_UCLAO' }, { key: '匹兹堡睡眠质量指数', value: 'YXT_PSQI' }, { key: '心境障碍问卷', value: 'YXT_MDQ' }, { key: '社会活动功能量表FAQ', value: 'YXT_FAQ' }]
                },
                {
                    bgColor: 'linear-gradient(0deg, #f8d2a6 0%, #efb27a 100%)', icon: '/img/popup_icon4.png', atitle: '人格与个性', btitle: '心理测评', value: '',
                    radios: [{ key: '艾森克个性问卷（EPQ）', value: 'YXT_EPQ' }, { key: '卡特尔16种人格因素测验', value: 'YXT_16PF' }, { key: '大五人格量表', value: 'YXT_OCEAN' }, { key: 'MBTI十六种人格问卷', value: 'YXT_MBTI' }, { key: '症状自评量表SCL-90', value: 'YXT_SCL90' }, { key: '明尼苏达多相人格测验', value: 'YXT_MMPI' }, { key: '一般健康问卷GHQ-12', value: 'YXT_GHQ-12' }, { key: '艾森克情绪稳定性测验', value: 'YXT_EEST' }]
                },
                {
                    bgColor: 'linear-gradient(0deg, #9471c7 0%, #733cc5 100%)', icon: '/img/popup_icon5.png', atitle: '孕产妇', btitle: '心理测评', value: '',
                    radios: [{ key: '妊娠压力量表', value: 'YXT_PPS' }, { key: '分娩恐惧量表', value: 'YXT_CAQ' }, { key: '爱丁堡产后抑郁量表', value: 'YXT_EPDS' }, { key: '产后抑郁症筛查量表', value: 'YXT_PDSS2' }, { key: '睡眠状况自评量表', value: 'YXT_SRSS' }, { key: '社会支持评定量表', value: 'YXT_SSRS' }]
                },
            ],
            value: '',
            vertoExto: 0,
            vertoAgentPwd: '',
            vertoSerIp: '',
            vertoSerPort: 0,
            callPhoneNumber: '',
            keyboardTxt: ''
        }
    },
    methods: {
        show(index, adress) {
            if(index == 0) {
                this.testOpenPopup();
            }
            if(index == 1) {
                getBook(1, 0, '').then(res => {
                    let resList = res.data;
                    console.log("id=", resList[0].id)
                    getInfo(resList[0].id).then(res => {
                    });
                });
                setTimeout(() => {
                    this.goto(adress);
                }, 500);  
            }
            if(index == 2) {
                this.chooseYykpShow = true;
            }
            if(index == 3){
                // this.msgOpenPopup();
                // gotoMusic() {
                //     this.$router.push({path:'/musicplayer',query:{ id: this.musics[0].id }});
                // },
                this.goto(adress);
            }
            if(index == 4) {
                this.chooseFwzxShow = true;
            }
            if(index == 5) {
                this.chooseFwsqShow = true;
            }
            if(index == 6){
                getHuiminPolicy(1, 0, '').then(res => {
                    let resList = res.data;
                    getInfo(resList[0].id).then(res => {
                    });
                });
                setTimeout(() => {
                    this.goto(adress);
                }, 500);
            }
            if(index == 7) {
                getComNetList().then(res => {
                    let resList = res.data;
                    getInfo(resList[0].id).then(res => {
                    });
                });
                setTimeout(() => {
                    this.goto(adress);
                }, 500);
            }
            if(index == 8){
                this.connectWebrtc();
                this.telPopup();
            }
            

            // if(index == 3) {
            //     getFuwu(1, 58, '').then(res => {
            //         let resList = res.data;
            //         getInfo(resList[0].id).then(res => {
            //         });
            //     });
            //     setTimeout(() => {
            //         this.goto(adress);
            //     }, 500);
            // }
            // if(index == 8){
            //     this.msgOpenPopup();
            // }
        },
        goto(adress, cate) {
            if(adress == '/mentality/tushu') cate = {openKey:0};
            if(adress == '/mentality/xinlijiankang') cate = {openKey:1};
            if(adress == '/mentality/courses') cate = {openKey:2};
            if(adress == '/musicplayer') cate = {id: this.musics[0].id};
            this.$router.push({ path: adress, query: cate });
        },
        telCancel(cancelType) {
            if (cancelType == 1) {
                this.telShow = false;
            } else if (cancelType == 2) {
                this.telConnected = false;
            } else if (cancelType == 3) {
                this.telRinging = false;
            } else if (cancelType == 4) {
                this.telActive = false;
            } else if (cancelType == 5) {
                this.telHangup = false;
            }
            logout();
        },
        connectWebrtc() {
            const webrtcId = window.localStorage.getItem('webrtcId');
            let webrtcIndex = window.localStorage.getItem('webrtcIndex');
            // 增加点击事件
            getInfo(webrtcId).then(res => {
                console.log("res=", res)
            });

            if (webrtcId == null || webrtcId == undefined || webrtcId == "" ||
                webrtcIndex == null || webrtcIndex == undefined || webrtcIndex == "") {
                this.telShow = false;
                this.telConnected = true;
                return;
            }
            console.log("webrtcIndex2", webrtcIndex);
            webrtcIndex = webrtcIndex.split("?")[0];
            console.log("webrtcIndex3", webrtcIndex);

            getWebrtcParams(webrtcId).then(res => {
                const webrtcParams = res.data[webrtcIndex];
                if (webrtcParams == null || webrtcParams == undefined || webrtcParams.articleFields == null) {
                    this.telConnected = true;
                    return;
                }
                this.vertoExto = webrtcParams.articleFields[0].fieldValue;
                this.vertoAgentPwd = webrtcParams.articleFields[1].fieldValue;
                this.vertoSerIp = webrtcParams.articleFields[2].fieldValue;
                this.vertoSerPort = webrtcParams.articleFields[3].fieldValue;
                this.callPhoneNumber = webrtcParams.articleFields[4].fieldValue;
                loginVerto(this.vertoExto, this.vertoAgentPwd, this.vertoSerIp, this.vertoSerPort,
                    () => {
                        logout();
                        this.telShow = true;
                        this.telHangup = false;
                        this.telConnected = false;
                        this.telRinging = false;
                    },
                    () => {
                        logout();
                        this.telHangup = true;
                        this.telShow = false;
                        this.telConnected = false;
                        this.telRinging = false;
                    }
                );
            });
        },
        ringUp() {
            doCall(this.vertoExto, this.callPhoneNumber,
                () => {
                    this.telRinging = true;
                    this.telActive = false;
                    this.telHangup = false;
                    this.telShow = false;
                    this.telConnected = false;
                },
                () => {
                    this.telActive = true;
                    this.telRinging = false;
                    this.telHangup = false;
                    this.telShow = false;
                    this.telConnected = false;
                });
        },
        telPopup() {
            this.keyboardTxt = "";
            decline();
            logout();
            this.telHangup = false;
            this.telActive = false;
            this.telShow = true;
            this.telConnected = false;
            this.telRinging = false;
        },
        testOpenPopup() {
            this.testShow = true;
        },
        testClosePopup() {
            this.testShow = false;
        },
        keyboardDown(keyboardNum) {
            this.keyboardTxt += keyboardNum;
            keyboardClick(keyboardNum);
        },
        createTestLink() {
            // console.log("this.value",this.value)
            if (this.value == '') {
                ElMessage({
                    message: "请选择您要测评的项目",
                    type: 'warning',
                })
                return;
            }
            getUserInfo(this.value).then(res => {
                const webrtcId = window.localStorage.getItem('webrtcId');
                let webrtcIndex = window.localStorage.getItem('webrtcIndex');
                if (webrtcId == null || webrtcId == undefined || webrtcId == "" ||
                    webrtcIndex == null || webrtcIndex == undefined || webrtcIndex == "") {
                    const depId = 3;
                    const jumpJrl = res.data.url + "&depId=" + depId;
                    console.log("jumpJrl=", jumpJrl)
                    window.location.href = jumpJrl;
                } else {
                    webrtcIndex = webrtcIndex.split("?")[0];

                    getWebrtcParams(webrtcId).then(WebrtcRes => {
                        const webrtcParams = WebrtcRes.data[webrtcIndex];
                        if (webrtcParams == null || webrtcParams == undefined || webrtcParams.articleFields == null) {
                            this.telConnected = true;
                            return;
                        }
                        const depId = webrtcParams.articleFields[5].fieldValue;
                        const jumpJrl = res.data.url + "&depId=" + depId;
                        console.log("jumpJrl=", jumpJrl)
                        window.location.href = jumpJrl;
                    });
                }
            }).catch(res => {
                if (res.response.status == 401) {
                    window.localStorage.setItem('modelKey', this.value);
                    this.$router.push('/newuser?value=' + encodeURIComponent(this.value));
                } else {
                    alert(res.response.statusText);
                }
            });
            // 每次登录都需要输入新账号
            // this.$router.push('/home?value='+encodeURIComponent(this.value));            
        },

        msgOpenPopup() {
            this.msgShow = true;
        },
        msgClosePopup() {
            this.msgShow = false;
        },

        chooseYykeClosePopup() {
            this.chooseYykpShow = false;
        },

        chooseYykeItem(fwzxType) {
            if (fwzxType == 1) { // 精神康复
                getJinshenkangfu(1, 0, '').then(res => {
                    let resList = res.data;
                    getInfo(resList[0].id).then(res => {
                    });
                });
                setTimeout(() => {
                    this.chooseYykpShow = false;
                    this.goto("/mentality/jinshenkangfu");
                }, 500);
            }else if(fwzxType == 2){ // 心理健康
                getXinlijiankang(1, 0, '').then(res => {
                    let resList = res.data;
                    getInfo(resList[0].id).then(res => {
                    });
                });
                setTimeout(() => {
                    this.chooseYykpShow = false;
                    this.goto("/mentality/xinlijiankang");
                }, 500);
            }
        },

        chooseFwzxClosePopup() {
            this.chooseFwzxShow = false;
        },

        chooseFwzxItem(fwzxType) {
            if (fwzxType == 1) { // 活动预告
                getZixunNotice(1, 0, '').then(res => {
                    let resList = res.data;
                    getInfo(resList[0].id).then(res => {
                    });
                });
                setTimeout(() => {
                    this.chooseFwzxShow = false;
                    this.goto("/eventNotice");
                }, 500);
            }else if(fwzxType == 2){ // 活动资讯
                getZixun(1, 0, '').then(res => {
                    let resList = res.data;
                    getInfo(resList[0].id).then(res => {
                    });
                });
                setTimeout(() => {
                    this.chooseFwzxShow = false;
                    this.goto("/event");
                }, 500);
            }
        },
        
        chooseFwsqClosePopup() {
            this.chooseFwsqShow = false;
        },

        chooseFwsqItem(fwsqType) {
            if (fwsqType == 1) { // 课程菜单
                getCourse(1, 0, '').then(res => {
                    let resList = res.data;
                    getInfo(resList[0].id).then(res => {
                    });
                });
                setTimeout(() => {
                    this.chooseFwsqShow = false;
                    this.goto("/mentality/courses");
                }, 500); 
            }else if(fwsqType == 2){ // 课程预约
                getCourseOrder(1, 0, '').then(res => {
                    let resList = res.data;
                    getInfo(resList[0].id).then(res => {
                    });
                });
                setTimeout(() => {
                    this.chooseFwsqShow = false;
                    this.goto("/mentality/coursesOrder");
                }, 500); 
            }else if(fwsqType == 3){ // 个体心理咨询预约
                this.chooseFwsqShow = false;
                this.msgOpenPopup();
            }
        },
    },
    mounted() {
        this.$root.showRight();
        if (this.$route.query.wen == 1) {
            this.testShow = true;
        }
        getBase().then(res => {
            this.base = res.data;
        });
        getMusicList().then(res => {
            this.musics = res.data;
        });
        new Swiper(".swiper-container", {
            slidesPerView: 3.4,
        });
    }


}
</script>

<style lang="scss">
@media (max-height: 1000px) {
    .index {
        padding: 40px 0 60px 0 !important;
    }

    .index-top {
        margin-bottom: 40px !important;
    }
}

.index {
    display: flex;
    flex-direction: column;
    width: 1400px;
    margin: 0 auto;
    padding: 60px 0;
    box-sizing: border-box;
    height: 100vh;

    .index-top {
        @include sys-flex(space-between, row);
        margin-bottom: 80px;
        flex: none;

        .logo {
            @include sys-picbox(438px, 80px);
            cursor: pointer;
        }

        .login {
            @include sys-flex(center, row);
            @include sys-boxsize(240px, 60px, 10px, #F6F8FF);
            @include sys-font(20px, $acolor);
            @include sys-picbox(17px, 20px);
            cursor: pointer;

            .picbox {
                margin-right: 10px;
            }
        }
    }

    .index-center {
        @include sys-flex();
        flex: 1;
        margin-bottom: 130px;


        .itemlist {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .item {
                display: flex;
                align-items: center;
                width: 440px;
                height: 150px;
                box-shadow: 0px 0px 50px 0px rgba(63, 101, 224, 0.05);
                border-radius: 20px;
                background: url(../assets/img/index_itembg.png) no-repeat center bottom/contain;
                margin-bottom: 40px;
                padding: 20px 50px;
                box-sizing: border-box;
                text-align: left;
                position: relative;
                cursor: pointer;

                &:hover {
                    .icon {
                        transform: translateY(50%) scale(1.1);
                    }
                }

                .txt {
                    width: 100%;
                    position: relative;
                    z-index: 5;

                    .t1 {
                        @include sys-text-hide(1);
                        @include sys-font(36px, #fff, bold);
                        line-height: 46px;
                    }

                    .t2 {
                        @include sys-text-hide(2);
                        @include sys-font(20px, #fff);
                        line-height: 24px;
                    }
                }

                .icon {
                    position: absolute;
                    bottom: 50%;
                    right: 50px;
                    transform: translateY(50%);
                    @include sys-picbox(90px, 90px);
                }
            }
        }
    }

    .index-bottom {
        @include sys-flex(space-between, row);
        width: 100%;
        height: 330px;

        .double,
        .single {
            height: 100%;
            background: #fff;
            border-radius: 20px;
            box-shadow: 0px 0px 50px 0px rgba(63, 101, 224, 0.05);
            padding: 40px 50px;
            box-sizing: border-box;
            text-align: left;

            .titlebox {
                @include sys-flex(space-between, row);
                cursor: pointer;

                .atitle {
                    flex: 1;
                    @include sys-text-hide(1);
                    @include sys-font(28px, #000, bold);
                    line-height: 30px;
                }

                .btitle {
                    flex: none;
                    padding: 0 10px;
                    cursor: pointer;
                    @include sys-picbox(22px, 16px);
                }
            }
        }

        .double {
            width: 920px;

            .itemlist {
                display: flex;
                width: calc(100% + 90px);
                height: 100%;
                overflow: hidden;
                margin: 0 -45px;

                .item {
                    width: 50%;
                    padding: 0 45px;

                    .qalist {
                        margin-top: 30px;

                        .qa-item {
                            @include sys-text-hide(1);
                            @include sys-font(16px, #000);
                            line-height: 40px;
                            cursor: pointer;
                        }
                    }

                    .musiclist {
                        margin-top: 40px;

                        .music {
                            @include sys-boxsize(100%, 80px, 10px, #F6F8FF);
                            display: flex;
                            align-items: center;
                            margin-bottom: 20px;
                            padding: 10px 30px;
                            box-sizing: border-box;
                            cursor: pointer;

                            .play {
                                @include sys-picbox(42px, 42px);
                                margin-right: 20px;
                            }

                            .music-title {
                                .atitle {
                                    display: flex;
                                    line-height: 24px;

                                    .title {
                                        @include sys-text-hide(1);
                                        @include sys-font(20px, #000);
                                    }

                                    .tag {
                                        @include sys-text-hide(1);
                                        @include sys-font(14px, #fff);
                                        background-color: $acolor;
                                        border-radius: 4px;
                                        flex: none;
                                        margin-left: 10px;
                                        padding: 0 6px;
                                    }
                                }

                                .btitle {
                                    @include sys-text-hide(1);
                                    @include sys-font(14px, #999);
                                }
                            }
                        }
                    }
                }
            }
        }

        .single {
            width: 440px;

            .item {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 100%;
                overflow: hidden;

                .introduce {
                    @include sys-text-hide(5);
                    @include sys-font(16px, #000);
                }

                .btn {
                    @include sys-boxsize(100%, 70px, 10px, $acolor);
                    @include sys-flex(center, row);
                    @include sys-picbox(22px, 22px);
                    cursor: pointer;

                    .icon {
                        flex: none;
                    }

                    .txt {
                        margin-left: 10px;
                        @include sys-text-hide(1);
                        @include sys-font(16px, #fff);
                    }
                }
            }
        }
    }

    .popup {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999;
        opacity: 0;
        visibility: hidden;
        &.msgbox{
            .tel-popup .txt{
                padding: 60px 60px 34px;
            }
        }
        &.active {
            opacity: 1;
            visibility: visible;
        }

        .shadow {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .8);
        }

        .tel-popup {
            @include sys-flex();
            width: 450px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .img {
                @include sys-picbox(192px, 193px);
                margin-bottom: -110px;
                position: relative;
                z-index: 5;
            }

            .txt {
                @include sys-font(24px, #000);
                line-height: 34px;
                width: 100%;
                padding: 106px 60px 34px;
                box-sizing: border-box;
                background: #FFFFFF;
                border-radius: 20px 20px 0 0;
            }

            .btns {
                width: 100%;
                height: 80px;
                display: flex;

                .btn {
                    @include sys-flex();
                    flex: 1;
                    height: 100%;
                    font-size: 24px;
                    cursor: pointer;

                    &:first-child {
                        background: #F6F6F6;
                        border-bottom-left-radius: 20px;
                        color: #666;
                    }

                    &:last-child {
                        background: $acolor;
                        border-bottom-right-radius: 20px;
                        color: #fff;
                    }
                }
            }
        }

        .tel-keyboard {
            @include sys-flex();
            width: 450px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .img {
                @include sys-picbox(192px, 193px);
                margin-bottom: -110px;
                position: relative;
                z-index: 5;
            }

            .txt {
                @include sys-font(24px, #000);
                line-height: 34px;
                width: 100%;
                padding: 106px 60px 34px;
                box-sizing: border-box;
                background: #FFFFFF;
                border-radius: 20px 20px 0 0;
            }

            .btns {
                width: 100%;
                height: 80px;
                display: flex;

                .keyboard {
                    @include sys-flex();
                    flex: 1;
                    height: 100%;
                    font-size: 24px;
                    cursor: pointer;
                    border: 2px solid #fff;

                    background: #F6F6F6;
                    color: #666;
                }

                .btn {
                    @include sys-flex();
                    flex: 1;
                    height: 100%;
                    font-size: 24px;
                    cursor: pointer;

                    &:first-child {
                        background: #F6F6F6;
                        border-bottom-left-radius: 20px;
                        color: #666;
                    }

                    &:last-child {
                        background: $acolor;
                        border-bottom-right-radius: 20px;
                        color: #fff;
                    }
                }
            }
        }

        .test-popup {
            width: 1400px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .tishi {
                @include sys-flex(space-between, row);
                margin-bottom: 60px;

                .txt {
                    max-width: 900px;

                    .t1 {
                        @include sys-text-hide(1);
                        @include sys-font(42px, #F6F8FF, bold);
                        margin-bottom: 20px;
                    }

                    .t2 {
                        @include sys-text-hide(2);
                        @include sys-font(28px, #F6F8FF);
                    }
                }

                .btn {
                    @include sys-radius-btn(180px, 60px, transparent);
                    border: 2px solid #fff;
                    font-size: 24px;
                    font-weight: bold;
                }
            }

            .cardlist {
                display: flex;
                align-items: center;
                margin: 0 -20px;

                .card {
                    @include sys-boxsize(400px, 540px, 20px);
                    padding: 50px 30px 50px 30px;
                    box-sizing: border-box;
                    overflow: hidden;
                    position: relative;
                    margin: 0 20px;
                    flex: none;

                    .card-main {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        z-index: 5;

                        .titlebox {
                            display: flex;
                            align-items: center;
                            margin-bottom: 25px;
                            flex: none;

                            .img {
                                width: 80px;
                                height: 80px;
                                @include sys-picbox(100%, 100%);
                                flex: none;
                            }

                            .title {
                                @include sys-font(28px, #fff);
                                margin-left: 30px;
                                flex: 1;

                                .atitle,
                                .btitle {
                                    @include sys-text-hide(1);
                                }

                                .atitle {
                                    font-weight: bold;
                                }
                            }
                        }

                        .input {
                            width: 100%;
                            flex: 1;
                            overflow: hidden;

                            &::-webkit-scrollbar {
                                width: 3px;
                            }

                            &::-webkit-scrollbar-thumb {
                                background: $acolor;
                                border-radius: 2px;
                            }

                            &::-webkit-scrollbar-resizer {
                                background: $acolor;
                            }

                            &::-webkit-scrollbar-track-piece {
                                background: #fff;
                                border-radius: 2px;
                            }

                            .radio {
                                width: 100%;
                                height: 50px;
                                display: flex;
                                align-items: center;
                                overflow: hidden;
                                @include sys-font(20px, #F6F8FF);

                                label {
                                    @include sys-text-hide(1);
                                    overflow: visible;
                                    position: relative;
                                    cursor: pointer;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        top: 50%;
                                        left: -40px;
                                        @include sys-radius-btn(20px, 20px, rgba(0, 0, 0, .15));
                                        transform: translateY(-50%);
                                        box-sizing: border-box;
                                    }
                                }

                                input:checked+label::before {
                                    background: url(../assets/img/popup_radio.png) no-repeat center center/contain;
                                    border: none;
                                }

                                input {
                                    @include sys-radius-btn(20px, 20px, #F8FAFE);
                                    border: 2px solid #BCC3D8;
                                    margin-right: 20px;
                                    opacity: 0;
                                }
                            }
                        }
                    }

                    .bg {
                        position: absolute;
                        bottom: 0;
                        right: 4px;
                        width: 235px;
                        height: 160px;
                        @include sys-picbox(100%, 100%);
                    }
                }

                .card:hover {
                    .card-main {
                        .input {
                            overflow: auto;
                        }
                    }
                }
            }
        }

        .fwzx-popup {
            @include sys-flex();
            width: 30%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .title {
                width: 20%;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: x-large;
                color: #fff;
                background: #4B8F39;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;

                margin-bottom: -20px;
                margin-right: 70%;
                // position: relative;
                z-index: 5;

                // @include sys-picbox(192px, 193px);
                // margin-bottom: -110px;
                // position: relative;
                // z-index: 5;
            }

            .txt {
                @include sys-font(24px, #000);
                width: 100%;
                padding: 60px 60px 70px;
                box-sizing: border-box;
                background: #FFFFFF;
                border-radius: 20px 20px 20px 20px;
                
                .btns {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    
                    .btn {
                        @include sys-flex();
                        flex: 1;
                        height: 100%;
                        font-size: 32px;
                        cursor: pointer;
                        background-color: #76CDD8;
                        border-radius: 20px 20px 20px 20px;
                        margin: 10px 10px;
                        color: #fff;

                        &:first-child {
                            background: #E67C7C;
                        }

                        &:last-child {
                            background: #9CD68C ;
                        }
                    }
                }
            }
        }

        .fwsq-popup {
            @include sys-flex();
            width: 60%;;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .title {
                width: 20%;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: x-large;
                color: #fff;
                background: #4B8F39;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;

                margin-bottom: -20px;
                margin-right: 70%;
                // position: relative;
                z-index: 5;

                // @include sys-picbox(192px, 193px);
                // margin-bottom: -110px;
                // position: relative;
                // z-index: 5;
            }

            .txt {
                @include sys-font(24px, #000);
                width: 100%;
                padding: 60px 60px 70px;
                box-sizing: border-box;
                background: #FFFFFF;
                border-radius: 20px 20px 20px 20px;
                
                .btns {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    
                    .btn {
                        @include sys-flex();
                        flex: 1;
                        height: 100%;
                        font-size: 32px;
                        cursor: pointer;
                        background-color: #76CDD8;
                        border-radius: 20px 20px 20px 20px;
                        margin: 10px 10px;
                        color: #fff;

                        &:first-child {
                            background: #E67C7C;
                        }

                        &:last-child {
                            background: #9CD68C ;
                        }
                    }
                }
            }
        }
    }
}
</style>